.scrollable-content{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding: 0 36px 0 36px;
    /* align-items: center; */
    flex: 1;
    height: 100%; 
    overflow-y: scroll;
  }

  @media (max-width: 920px) {
    .scrollable-content{
      margin-top: 80px;
      height: auto;
      overflow-y: visible;
    }
  }