/* App.css */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
}
.app {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Adjust this to your preferred height */
  font-size: 16px;
  font-weight: 400;
}
section {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h3 {
  font-size: 24px;
}

.steps {
  margin: 140px 15% 140px 15%;
}
.grid {
  display: grid;
  grid-gap: 10px;
  align-self: center;
  max-width: 500px;
  grid-template-columns: repeat(auto-fill, 75px);
  margin-bottom: 100px;
}
.grid > div {
  cursor: pointer;
}
.grid > div:hover {
  background-color: #f5f5f5;
  scale: 1.05;
}
.grid > * {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #000;
}
@media (max-width: 920px) {
  .app {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .steps {
    width: 90%;
    /* align-self: center; */
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .grid {
    /* margin: auto; */
    align-self: center;
    width: 90vw;
  }
}
