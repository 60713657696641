header {
  position: sticky;
  top: 0;
  z-index: 10000;
  background: #dadbf8;
  display: flex;
  flex-direction: row;
  padding: 0px 15vw;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logoLine {
  height: 50px;
  gap: 10px;
}
.navigation {
  gap: 45px;
}
.navigation > a {
  gap: 15px;
  color: black;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
a:hover {
  color: rgb(64, 64, 64);
}

.dropdown {
  display: none;
}
@media (max-width: 920px) {
  header {
    padding: 0px 5vw;
  }
  .navigation {
    display: none;
  }
  .dropdown {
    display: block;
  }
  .dropdown-menu {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .dropdown-menu > a {
    display: block;
    padding: 10px;
    color: black;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px #dedede solid;
  }
}
